<template>
  <div>
    <div class="page-back">
      <NavBar class="page-title" :iconColor="'#fff'">
        <div slot="title">分销团队</div>
      </NavBar>
      <div class="content-layout">
        <div class="grade-info">
          <div class="superior-icon">上级</div>
          <div class="head-portrait-box">
            <div class="user-info" v-if="teamInfo.parent.id * 1 > 0">
              <div class="head-img-box">
                <img :src="teamInfo.parent.head_img || headImgUrl" />
              </div>
              <div class="user-name">{{ teamInfo.parent.nickname }}</div>
            </div>
            <div class="fen-ge-xian" v-if="teamInfo.parent.id * 1 > 0"></div>
            <div class="user-info">
              <div class="head-img-box">
                <img :src="teamInfo.member.head_img || headImgUrl" />
              </div>
              <div class="user-name">{{ teamInfo.member.nickname }}</div>
            </div>
          </div>
          <div class="team-quantity">
            <div class="details">
              <span>{{ teamInfo.team_num.total_num }}</span>
              <div>团队人数</div>
            </div>
            <div class="details">
              <span>{{ teamInfo.team_num.one_num }}</span>
              <div>一级人数</div>
            </div>
            <div class="details">
              <span>{{ teamInfo.team_num.two_num }}</span>
              <div>二级人数</div>
            </div>
          </div>
        </div>
        <div>
          <div class="detail-padding">
            <div class="detail-class-layout">
              <div
                class="detail-class-text"
                :class="{ 'detail-class-dynamic': active === index }"
                v-for="(item, index) in classList"
                :key="index"
                @click="onClass(index)"
              >
                <span>{{ item }} </span>
                <div
                  class="markedness"
                  :class="{ highlight: active === index }"
                ></div>
              </div>
            </div>
            <div class="detail-card">
              <div v-if="list.length <= 0" class="prompt-img-box">
                <img
                  src="~@/assets/blank/collect-blank-images@2x.png"
                  class="prompt-img"
                />
                <span>当前没有团队</span>
              </div>
              <van-list
                v-model="loading"
                :finished="finished"
                offset="20"
                @load="onLoad"
                v-else
              >
                <van-cell v-for="(item, i_index) in list" :key="i_index">
                  <div class="list-box">
                    <div class="icon-img-box">
                      <img :src="item.head_img" class="icon-img" />
                    </div>
                    <div class="info-user">
                      <div class="level">
                        <span>{{ item.nickname }}</span>
                        <div class="grade">
                          {{ item.type * 1 == 1 ? "一级" : "二级" }}
                        </div>
                      </div>
                      <div class="league-for-time">
                        入团时间：{{ item.created_at }}
                      </div>
                    </div>
                  </div>
                </van-cell>
              </van-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { getTeamInfo, getTeamList } from "@/api/member";

export default {
  name: "divide-sell",
  components: {
    NavBar,
  },
  data() {
    return {
      headImgUrl: require("@/assets/common/personal_centerhead_portrait.png"),
      active: 0, // 分类样式显示
      activeNum: 1, // 余额消费金显示
      currentDate: new Date(),
      classList: ["全部人员", "一级", "二级"],
      finished: false, // 是否加载完成
      loading: false, // 是否开启加载
      list: [],
      page: 1,
      teamInfo: {
        parent: { id: 0 },
        member: { head_img: "" },
        team_num: { one_num: 0, two_num: 0, total_num: 0 },
      },
      isAjax: false,
    };
  },
  mounted() {
    this.getTeamInfo();
    this.getTeamList();
  },
  methods: {
    async onLoad() {
      this.getTeamList();
      this.loading = false;
    },
    async getTeamInfo() {
      const res = await getTeamInfo();
      if (res.code * 1 == 1) {
        this.teamInfo = res.data;
      }
    },
    async getTeamList() {
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const res = await getTeamList({ type: this.active });
      this.list = [...this.list, ...res.data.items];
      if (this.list.length >= res.data.total_num * 1) {
        this.finished = true;
      }
      ++this.page;
      this.isAjax = false;
    },
    onClass(index) {
      if (this.active === index) {
        return false;
      }
      this.active = index;
      this.list = [];
      this.page = 1;
      this.isAjax = false;
      this.getTeamList();
    },
  },
};
</script>

<style lang="less" scoped>
.page-title {
  color: #fff;
}

.page-back {
  height: 100vh;
  background: url("~@/assets/common/background-img-6@2x.png") no-repeat left top /
    100%;
}

.content-layout {
  padding: 58px 12px 12px 12px;
}

.grade-info {
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  padding: 18px 12px 18px 12px;

  .superior-icon {
    position: absolute;
    top: 0;
    left: 14px;
    width: 26px;
    text-align: center;
    line-height: 26px;
    background-color: #ffd3cc;
    border-radius: 0 0 26px 26px;
    padding: 10px 0;
    color: #e61f18;
    writing-mode: vertical-lr;
    font-size: 17px;
  }
}

// 头像栏
.head-portrait-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .user-name {
      font-size: 17px;
    }
    .head-img-box {
      width: 78px;
      margin-bottom: 10px;
      img {
        width: 78px;
        height: 78px;
        display: block;
        border-radius: 50%;
        box-shadow: 0px 0px 6px rgba(154, 151, 151, 0.2);
      }
    }
  }

  .fen-ge-xian {
    width: 1px;
    height: 66px;
    background-color: #ededed;
  }
}

.team-quantity {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 21px;
      font-weight: 500;
    }

    div {
      font-size: 16px;
      color: #6f6f6f;
    }
  }
}

// 明细
.detail-padding {
  .no-record {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 12px;
  }
}

.detail-class-layout {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .detail-class-text {
    position: relative;
    text-align: center;
    padding: 10px 0;
    width: 90px;
    font-size: 18px;
    color: #6f6f6f;
    .markedness {
      display: none;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 34px;
      height: 3px;
      background-color: #eb0e05;
    }
    .highlight {
      display: block;
    }
  }
  .detail-class-dynamic {
    // padding: 10px 0;
    // width: 90px;
    // text-align: center;
    // border-radius: 999px;
    // background-color: #ed301d;
    // color: #fff;
    color: #eb0e05;
    // transition: all 0.3s;
  }
}

// 列表
.detail-card {
  padding: 12px;
  background-color: #fff;
  border-radius: 16px;
}

.prompt-img-box {
  padding: 40px 60px 140px 60px;
  text-align: center;
  span {
    margin-top: 12px;
    font-size: 18px;
    color: #aaa;
  }
}

.prompt-img {
  width: 220px;
  display: block;
  margin-bottom: 10px;
}

.van-cell {
  padding: 0;
}

.list-box {
  display: flex;
  align-items: center;
  padding: 12px 0;

  .icon-img-box {
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    .icon-img {
      display: block;
      width: 50px;
      height: 50px;
    }
  }

  .info-user {
    font-size: 16px;
    padding-left: 10px;

    .level {
      display: flex;
      align-items: center;
      font-size: 17px;
      .grade {
        width: 47px;
        height: 22px;
        background: #ffffff;
        border: 1px solid #da432e;
        opacity: 1;
        border-radius: 13px;
        font-size: 14px;
        color: #da432e;
        margin-left: 10px;
        text-align: center;
        line-height: 22px;
      }
    }

    .league-for-time {
      font-size: 13px;
      margin-top: 6px;
      color: #6f6f6f;
    }
  }
}
</style>
